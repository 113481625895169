import { Button, Result, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import { lazy, Suspense } from 'react';
import { Navigate, RouterProvider, createBrowserRouter, redirect, useNavigate, useRouteError } from 'react-router-dom';

import { HTTP } from 'api/http';
import { IUserAuthData } from 'api/models/IAuth';
import { useAuthStore } from 'stores/authStore';

const checkAuth = async () => {
    const { user, setUser } = useAuthStore.getState();
    if (!user) {
        try {
            const response: AxiosResponse<IUserAuthData> = await HTTP.post('auth/config');
            console.log(response.data);
            setUser(response.data);
            return response.data;
        } catch {
            return null;
        }
    }
    return user;
};
// +7 (989) 089-65-26
// rtbhtrnrgewg
const loginLoader = async () => {
    const user = await checkAuth();
    return user ? redirect('/dashboard') : null;
};

const protectedLoader = async () => {
    const user = await checkAuth();
    return user ? null : redirect('/login');
};

export const RootErrorBoundary = () => {
    const error = useRouteError() as Error | null;
    const navigate = useNavigate();

    const handleReload = () => {
        navigate('/');
    };

    console.error(error);

    return (
        <Result
            status="warning"
            title="Произошла ошибка, обратитесь в IT отдел."
            extra={
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
                    <span className="text-red">{error ? error.message : 'Неизвестная ошибка'}</span>
                    <Button type="primary" onClick={handleReload}>
                        Перезагрузить
                    </Button>
                </div>
            }
        />
    );
};

const routes = [
    {
        path: '/dashboard',
        Component: lazy(() => import('./App')),
        errorElement: <RootErrorBoundary />,
        children: [
            { path: '', Component: lazy(() => import('./containers/Index')) },
            { path: 'users', Component: lazy(() => import('./containers/Users')) },
            { path: 'building', Component: lazy(() => import('./containers/Building')) },
            { path: 'buildings', Component: lazy(() => import('./containers/Buildings')) },
            { path: 'clients', Component: lazy(() => import('./containers/Clients')) },
            { path: 'requests', Component: lazy(() => import('./containers/Requests')) },
            { path: 'scheduler', Component: lazy(() => import('./containers/Scheduler')) },
            { path: 'telephony', Component: lazy(() => import('./containers/Telephony')) },
            { path: 'legal', Component: lazy(() => import('./containers/Legal')) },
            { path: '*', Component: lazy(() => import('./containers/Pages/NotFound')) },
        ],
        loader: protectedLoader,
    },
    {
        path: '/login',
        Component: lazy(() => import('./containers/Pages/SignIn')),
        loader: loginLoader,
    },
    {
        path: '/',
        element: <Navigate to="/dashboard" />,
        loader: protectedLoader,
    },
];

const router = createBrowserRouter(routes);

const AppRoutes = () => (
    <Suspense fallback={<Spin tip="Загрузка" size="large" fullscreen />}>
        <RouterProvider router={router} future={{ v7_startTransition: true }} />
    </Suspense>
);

export default AppRoutes;
