import { notification } from 'antd';
import clsx, { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

import Funcs from './funcs';
import Vars from './vars';

export const cn = (...classes: ClassValue[]) => twMerge(clsx(...classes));

export const notificationError = (message: string, error: unknown) => {
    console.error(error);
    notification.error({
        message,
        description: error instanceof Error ? error.message : 'Неизвестная ошибка',
    });
};

const Utils = { Funcs, Vars };

export default Utils;
