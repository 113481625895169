import { create } from 'zustand';

import { HTTP } from 'api/http';
import { IUserAuthData } from 'api/models/IAuth';
import { notificationError } from 'utils/index';

interface IAuthState {
    user: IUserAuthData | null;
    setUser: (user: IUserAuthData | null) => void;
    logout: () => Promise<void>;
}

export const useAuthStore = create<IAuthState>((set) => ({
    user: null,
    setUser: (user: IUserAuthData | null) => set({ user }),
    logout: async () => {
        try {
            await HTTP.post('auth/logout');
        } catch (error) {
            notificationError('Ошибка при выходе из системы', error);
        } finally {
            set({ user: null });
        }
    },
}));
