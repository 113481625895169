import dayjs from 'dayjs';

import Utils from 'utils';

class Funcs {
    normalizeDP(value: string | [string | undefined, string | undefined] | undefined, options: { hasTime?: boolean; isRange?: boolean } = {}): string | [string | undefined, string | undefined] | undefined {
        const format = options.hasTime ? Utils.Vars.dateTimeFormat : Utils.Vars.dateFormat;
        if (options.isRange && Array.isArray(value)) {
            const [start, end] = value;
            return [start ? dayjs(start).format(format) : undefined, end ? dayjs(end).format(format) : undefined];
        } else if (typeof value === 'string') {
            return value ? dayjs(value).format(format) : undefined;
        }
        return undefined;
    }

    getValuePropsDP(value: string | [string | undefined, string | undefined] | undefined, options: { hasTime?: boolean; isRange?: boolean } = {}) {
        const format = options.hasTime ? Utils.Vars.dateTimeFormat : Utils.Vars.dateFormat;
        if (options.isRange && Array.isArray(value)) {
            const [start, end] = value;
            return {
                value: [start ? dayjs(start, format) : undefined, end ? dayjs(end, format) : undefined],
            };
        } else if (typeof value === 'string') {
            return {
                value: value ? dayjs(value, format) : undefined,
            };
        }
        return { value: undefined };
    }

    normalizePriceFormat(val: number | string | null | undefined): string | null | undefined {
        if (val === null || val === undefined) {
            return val;
        }

        const stringValue = val.toString();

        return stringValue.replace(/\s+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    getLayoutPrice(item: any): [string | null, string | null] {
        let price: string | null = null;
        let priceOne: string | null = null;

        const isAvailable = item.l_status && item.l_status.toLowerCase() === 'свободно';

        if (isAvailable) {
            if (item.l_price) {
                price = `${this.normalizePriceFormat(item.l_price)} ₽`;
            }
            if (item.l_price_one) {
                priceOne = `${this.normalizePriceFormat(item.l_price_one)} ₽/м²`;
            }

            if (!item.l_price_one && item.l_price && item.l_area) {
                priceOne = `${this.normalizePriceFormat(Math.round(item.l_price / item.l_area))} ₽/м²`;
            }

            if (!item.l_price && item.l_price_one && item.l_area) {
                price = `${this.normalizePriceFormat(Math.round(item.l_price_one * item.l_area))} ₽`;
            }
        }

        return [price, priceOne];
    }

    mapToSelect = (labelMap: { [key: string]: { id: string; title: string; color: string } }) => {
        return Object.entries(labelMap).map(([value, { title }]) => ({
            label: title,
            value,
        }));
    };

    arrayToSelect = (items: string[]) => {
        return items.map((item) => ({
            label: item,
            value: item === '' ? null : item,
        }));
    };

    truncate = (input: string, max?: number) => (input && input.length > (max || 150) ? `${input.substring(0, max || 150)}...` : input);

    filterCols = (all_cols: any[], access_cols: any[]) => {
        return all_cols.filter((item) => item.key === 'xs' || access_cols.includes(item.key));
    };

    fromToFormat = (from?: number | string | null, to?: number | string | null): string => {
        const prefixFrom = 'от ';
        const prefixTo = 'до ';

        const v_from = from ? `${prefixFrom}${from} ` : '';
        const v_to = to ? `${prefixTo}${to}` : '';

        return v_from || v_to ? `${v_from}${v_to}`.trim() : '';
    };

    phoneValidator = (_: any, value: string) => {
        const phoneNumber = value ? value.replace(/\D/g, '') : '';
        if (phoneNumber.length > 1 && phoneNumber.length !== 11) {
            return Promise.reject(new Error('Введите полный номер'));
        }
        return Promise.resolve();
    };
}

const funcs = new Funcs();
export default Object.freeze(funcs);
